<template>
<div class="blog-container" :class="'route-'+$route.name" :style="{height: innerHeight + 'px'}">
    <template v-if="$parent.isMobile">
        <router-view name="mobile">
        </router-view>
    </template>

    <slideout-panel v-else></slideout-panel>
    <vs-row justify="flex-end" v-if="user != null && user.is_admin && !$parent.isMobile" style="position: fixed; top: 50px; right: 50px; width: 100%;">
        <vs-button size="large" @click="createNew">Crea Nuovo</vs-button>

    </vs-row>
    <div class="blog-posts" v-if="!$parent.isMobile && ($route.name == 'Blog' || $route.name == 'Post') || ($parent.isMobile && $route.name == 'Blog')">
        <div class="header">
            <h2 style="margin: 0;">{{ $t('blog.title') }}</h2>
            <p style="margin: 0;">{{ $t('blog.caption') }}</p>
        </div>

        <PostPreview :user="user" v-for="article in articles" :article="article" :key="'article-' + article.id" @click.native="$router.push({path: '/blog/post/' + (article.slug != null && article.slug != '' ? article.slug : article.id)})" />
    </div>
</div>
</template>

<script>
import BreadCumbWrapper from '../components/BreadCumbWrapper.vue';
import PostPreview from '../components/PostPreview.vue';
import Post from '../components/elements/Post.vue';
import {
    apiCall
} from '../utils/ApiMiddleware';
export default {
    name: 'App',
    components: {
        PostPreview
    },

    props: {
        user: {
            type: Object,
            default: null
        },

        userLoaded: {
            type: Boolean,
            default: false
        },

        innerHeight: {
            type: Number,
            default: 100,
            required: false
        }
    },

    data() {
        return {
            articles: [],
            panel: null,

            /* DIALOGS */

            dialogComponent: null,

            after: null,
            result: null

        }
    },
    created() {

    },
    beforeDestroy() {

    },

    mounted() {
        if (this.$route.name != 'Blog') { // l'utente sta provando a visitare direttamente una sotto-rotta (eg. visitando viaggi da contact-us)
            this.showRoute(this.$route)
        }
        
        if(this.userLoaded){
            this.getBlogArticles();
        }

        let el = document.getElementsByClassName('blog-container')[0];
        el.onscroll = () => {
            let e = document.getElementsByClassName('blog-container')[0];
            let bottomOfWindow = Math.abs(e.scrollTop - (e.scrollHeight - e.offsetHeight)) < 20;
            if (bottomOfWindow) {
                if (this.result != null && this.result.after != null) { // non ho finito le pagine e non sta caricando
                    this.after = this.result.after;
                }
            }
        };
    },

    methods: {
        randomSlug(){
            // generate a temporary slug
            // like new-article-XXXXXXX
            return 'new-article-' + Math.random().toString(36).substring(7);
        },

        async createNew() {
            const loading = this.$vs.loading();
            const response = await apiCall('POST', '/backoffice/blog/articles', {
                title: 'Nuovo articolo',
                text: 'Testo articolo',
                published: false,
                slug: this.randomSlug(),
            });
            this.getBlogArticles();
            loading.close();
            if (response.status == 200) {
                this.$router.push({
                    path: '/blog/post/' + response.data.id
                })

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile creare un nuovo articolo',
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        async getBlogArticles(reset = true) {
            const loading = this.$vs.loading();
            if (this.panel != null) {
                await this.panel.hide();
                this.panel = null;
            }
            const response = await apiCall('GET', (this.user != null && this.user.is_admin ? '/backoffice' : '') + '/blog/articles', {
                limit: 5,
                after: this.after,
                order: 'desc(updated_at)'
            });
            loading.close();
            if (response.status == 200) {
                if (reset) {
                    this.articles = [];
                }
                this.result = response.data;
                if (response.data.data != null) {
                    this.articles = this.articles.concat(response.data.data);
                }

            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: this.$t('blog.messages.cannotLoadPosts'),
                    color: 'danger',
                    position: 'top-right'
                });
            }
        },

        showRoute(n) {
            // do nothing from mobile
            if (this.$parent.isMobile) {
                return;
            }
            
            if (this.panel != null) {
                this.panel.hide();
                this.panel = null;
            }
            if (!n.meta.side) {

                return;
            }

            setTimeout(() => {
                this.panel = this.$showPanel({
                    component: BreadCumbWrapper,
                    openOn: 'right',
                    removeBg: true,
                    props: {
                        component: Post,
                        name: this.$t('routes.Blog'),
                        back: () => {
                            this.$router.replace({path: '/blog'})
                        },
                        user: this.user,
                        params: {
                            deleted: this.getBlogArticles
                        }
                    }
                });

                this.panel.promise
                .then(result => {
                    if (this.panel != null && this.panel.id == result.id) { // c'è un panel aperto, ed è lo stesso che si sta chiudendo
                        this.$router.push('/blog/')
                        this.panel = null;
                    }
                });

            }, 200);

            // disable overflow-y of .blog-container
            let el = document.getElementsByClassName('blog-container')[0];
            el.style.overflow = 'hidden';

            
        }
    },

    watch: {
        '$route'(n) {
            this.showRoute(n);
        },

        after() {
            this.getBlogArticles(false);
        },

        user(){
            if(this.articles.length == 0){
                this.getBlogArticles();
            }
        },

        userLoaded(n){
            if(n && this.articles.length == 0){
                this.getBlogArticles();
            }
        },

        panel(n) {
            // if n is not null block scrolling of page .blog-containe
            let el = document.getElementsByClassName('blog-container')[0];
            if (n != null) {
                el.style.overflow = 'hidden';
            } else {
                el.style.overflow = 'auto';
            }
        }
    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-bottom: 10px;
}

.blog-posts {
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.blog-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 80px;
    box-sizing: border-box;

}

.blog-posts .vs-card-content.type-1 {
    max-width: 350px;
}

/* MOBILE */
@media (max-width: 600px) {
    .blog-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;
        padding-bottom: 100px;
    }

    .blog-posts {
        padding-top: 20px;
        width: 100%;
    }
}
</style>
